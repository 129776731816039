import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {StyleFlexContainer} from "../../styles/app";
import LandingPage from "./LandingPage";
import AboutUs from "./AboutUs";
import SharlInfo from "./SharlInfo";

function Home(props) {
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        setPageNumber(props.AppReducer.page)
    },[props.AppReducer])

    useEffect(() => {
        function handleKeyDown(e) {
            const keyPressed = e.keyCode;
            if (keyPressed===40){
                //down key
                if (pageNumber >= 0 && pageNumber < 2){
                    setPageNumber(pageNumber+1);
                }
            } else if (keyPressed===38){
                //up key
                if (pageNumber > 0) {
                    setPageNumber(pageNumber - 1);
                }
            }
        }
            document.addEventListener('keydown', handleKeyDown);
            // Don't forget to clean up
            return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [pageNumber]);

    return (
        <StyleFlexContainer bgColor={"#336699"} bgImage={"linear-gradient(#2a2a72ff, #336699)"} flexJustify={"space-around"}>
        {pageNumber === 0 ?
            <LandingPage />
        :""}
        {pageNumber === 1 ?
           <AboutUs/>
        :""}
        {pageNumber === 2 ?
            <SharlInfo/>
        :""}
    </StyleFlexContainer>
);
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(Home);
import React from 'react';
import {FooterStyleContainer} from "../../styles/app";

function Footer() {
  return (
        <FooterStyleContainer>
          <p>© So Sharl Media LLC. All rights reserved.</p>
        </FooterStyleContainer>
  );
}

export default Footer;
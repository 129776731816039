import React from 'react'
import {connect} from "react-redux";
import {AppStyleContainer, StyleContainer} from "../../styles/app";
import Footer from "./Footer";

function Layout(props) {
        return (
            <AppStyleContainer>
                <StyleContainer height={"100%"}>
                    {props.children}
                </StyleContainer>
                <Footer/>
            </AppStyleContainer>
        )
    }

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(Layout);

import styled, {createGlobalStyle} from "styled-components";
import Quicksand from "../assets/fonts/Quicksand.ttf";

export const GlobalStyle = createGlobalStyle`
  body {
    height: 100vh;
    width: 100%;
  }
  * {
    margin: 0;
    padding: 0;
  }
  a:-webkit-any-link {
    color: white;
    text-decoration: none;
}
 @font-face {
    font-weight: normal;
    font-style: normal;
    font-family: Quicksand;
    src: url(${Quicksand}) format('truetype');
    
  }
`;

export const AppStyleContainer = styled.div`
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Quicksand;
    position: relative;
    z-index: 0;   
    `;

export const StyleContainer = styled.div`
    height: ${props=>props.height || "15%"};
    width: ${props=>props.width || "100%"};
    `;

export const FooterStyleContainer = styled.div`
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0px;
    z-index: 100;
    
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        color: white;
        font-size: 1vw;
    }
    `;

export const StyleFlexContainer = styled.div`
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: ${props=>props.flexDirection || "row"};
    align-items: ${props=>props.flexAlign || "center"};
    justify-content: ${props=>props.flexJustify || "center"};
    
    background-color: ${props=>props.bgColor || "white"};
    background-image: ${props=>props.bgImage || "none"};
    
    p {
        font-size:${props=>props.fontSize || "2vw"};
        line-height: ${props=>props.lineHeight || "white"};
        color: ${props=>props.fontColor || "white"};
    }
    `;


export const StyleImageContainer = styled.div`
    height: ${props=>props.height || "20%"};
    width: ${props=>props.width || "20%"};
    img {
        width: 100%;
        height: auto;
    }
    h1 {
        color: white;
        font-size: 2vw;
        font-weight: bold;
        line-height: 4;
    }
    p {
        line-height: 2;
    }
        position: relative;
    z-index: 1;

    display: ${props=>props.hideSharl || "flex"};
    flex-direction: ${props=>props.flexDirection || "column"};
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 1000px) {
    display: ${props=>props.hideMediaSharl || "flex"};

        img {
            height: 100vh;
            width: 100vw;
            object-fit: fit;
        }   
        h1 {
            font-size: 4.5vh;
            font-weight: bold;
            line-height: 3;
        }
        p {
           line-height: 1.5;
           font-size: 3vh;
           text-align: center;
           padding-left: 3%;  
           padding-right: 3%;  
        }
    }
       
    `;

export const StyleFloatContainer = styled.div`
    height: ${props=>props.height || "12%"};
    width: ${props=>props.width || "8%"};
    position: absolute;
    
    z-index: 10;
    right: ${props=>props.right || "4%"}; 
    bottom: ${props=>props.bottom || "5%"};
    transform: ${props=>props.transform || "none"};
    opacity: ${props=>props.opacity || "1"};
    
    
    display: ${props=>props.hideSharl || "flex"};
    flex-direction: ${props=>props.flexDirection || "row"};
    align-items: ${props=>props.flexAlign || "center"};
    justify-content: ${props=>props.flexJustify || "center"};
    
    @media (max-width: 800px) {
        display: ${props=>props.hideMediaSharl || "flex"}
        }   
        
    img {
        height: 80%;
        width: auto;
    }
    
    `;
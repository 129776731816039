import React from 'react';
import {StyleFloatContainer, StyleImageContainer} from "../../styles/app";
import updatePageFunction from "../../store/actions/updatePageAction";
import {connect} from "react-redux";

function SharlInfo(props) {

    const pageHandler = (goToPage) => {
        props.dispatch(updatePageFunction(goToPage));
    }

    return (
        <StyleImageContainer height={"100%"} width={"100%"}>
            <h1>What is Sharl Info?</h1>
            <p>Sharl info is the home of the Sharl.</p>
            <p>You can play games with your Sharl and dress them in fancy hats.</p>
            <p>Your Sharls can be sent to research fun facts and stories.</p>
            <p>Or you can sit back and watch them zoom about in their tank.</p>
            <p>Launching 2023</p>
            <StyleFloatContainer transform={"rotate(180deg)"}>
                <img src={require("../../assets/images/down.png")} alt={"continue"} onClick={()=>pageHandler(0)} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"20%"} bottom={"80%"} opacity={"0.6"}>
                <img src={require("../../assets/images/cute_sharl.png")} alt={"cute sharl"} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"20%"} bottom={"82%"} opacity={"1"}>
                <img src={require("../../assets/images/hat-top.png")} alt={"top hat"} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"65%"} bottom={"60%"} opacity={"0.6"} hideMediaSharl={"none"}>
                <img src={require("../../assets/images/wharl.png")} alt={"wharl"} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"65%"} bottom={"85%"} opacity={"0.6"} hideSharl={"none"}>
                <img src={require("../../assets/images/wharl.png")} alt={"wharl"} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"80%"} bottom={"30%"} opacity={"0.6"} hideMediaSharl={"none"}>
                <img src={require("../../assets/images/barl.png")} alt={"cute sharl"} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"80%"} bottom={"10%"} opacity={"0.6"} hideSharl={"none"}>
                <img src={require("../../assets/images/barl.png")} alt={"cute sharl"} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"90%"} bottom={"75%"} opacity={"0.6"}>
                <img src={require("../../assets/images/cute_sharl.png")} alt={"cute sharl"} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"90%"} bottom={"77%"} opacity={"1"}>
                <img src={require("../../assets/images/fedora.png")} alt={"fedora"} />
            </StyleFloatContainer>
            <StyleFloatContainer right={"10%"} bottom={"20%"} opacity={"0.6"}>
                <img src={require("../../assets/images/cute_sharl.png")} alt={"cute sharl"}/>
            </StyleFloatContainer>
        </StyleImageContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(SharlInfo);
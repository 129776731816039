import {pagenumber} from "../types";

export const updatePageAction = (data) => ({
    type: pagenumber,
    payload: data,
});

const updatePageFunction = (data) => (dispatch) => {
    dispatch(updatePageAction(data));
};

export default updatePageFunction;
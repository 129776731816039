import React from 'react';
import {StyleFloatContainer, StyleImageContainer} from "../../styles/app";
import updatePageFunction from "../../store/actions/updatePageAction";
import {connect} from "react-redux";

function AboutUs(props) {

    const pageHandler = (goToPage) => {
        props.dispatch(updatePageFunction(goToPage));
    }

    return (
        <StyleImageContainer height={"100%"} width={"100%"}>
            <h1>About</h1>
            <p>So Sharl Media LLC is an independent media & gaming company with a focus on user experience.</p>
            <p>Our inaugural game Sharl Info reimagines the digital pet genre for the 21st century. </p>
            <p>Our company takes an innovative approach to gaming and prides itself on fair pricing models.</p>
            <p>No loot boxes or pay to win mechanics.</p>
            <StyleFloatContainer>
                <img src={require("../../assets/images/down.png")} alt={"continue"} onClick={()=>pageHandler(2)} />
            </StyleFloatContainer>
        </StyleImageContainer>
    );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(AboutUs);
import {
    pagenumber
} from "../types";

const initialState = {
    page: 0,
  };

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
      case pagenumber:
            return {
            ...state,
            page: action.payload,
        }
        default:
            return {
                ...state,
            };
  }
}

export default AppReducer;
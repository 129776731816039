import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {GlobalStyle} from "./styles/app";
import Home from "./containers/Home";
import Layout from "./containers/Layout";


function App() {
  return (
      <BrowserRouter>
        <GlobalStyle />
          <Switch>
              <Layout>
                <Route exact path="/" component={Home} />
              </Layout>
            </Switch>
      </BrowserRouter>
  );
}

export default App;

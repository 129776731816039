import React from 'react';
import {StyleFloatContainer, StyleImageContainer} from "../../styles/app";
import updatePageFunction from "../../store/actions/updatePageAction";
import {connect} from "react-redux";

function LandingPage(props) {

    const pageHandler = (goToPage) => {
        props.dispatch(updatePageFunction(goToPage));
    }

    return (
        <>
            <StyleImageContainer height={"100%"} width={"100%"} hideMediaSharl={"none"}>
                <img src={require("../../assets/images/logo.png")} alt={"so sharl media"}/>
                <StyleFloatContainer>
                    <img src={require("../../assets/images/down.png")} alt={"continue"} onClick={()=>pageHandler(1)} />
                </StyleFloatContainer>
            </StyleImageContainer>
            <StyleImageContainer height={"100%"} width={"100%"} hideSharl={"none"}>
                <img src={require("../../assets/images/logo_mobile.png")} alt={"so sharl media"}/>
                <StyleFloatContainer>
                    <img src={require("../../assets/images/down.png")} alt={"continue"} onClick={()=>pageHandler(1)} />
                </StyleFloatContainer>
            </StyleImageContainer>
        </>
        );
}

const mapStateToProps = state => {
  return {
      ...state,
    };
  };

export default connect(mapStateToProps)(LandingPage);